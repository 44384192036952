import React,{useState,useEffect,useRef} from 'react';
import {connect} from 'react-redux';

import {currentRouteSet} from '../../actions/actions';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid'; 
import {BrowserRouter as Router,Route,Link} from 'react-router-dom'
import {pathSpliter,_p,sleep,dateTimeFormat,dateFormat,currentDateTime,getDateTimeFromISODT,currentDateStartTime,currentDateEndTime} from '../../lib/functions'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DdatateIcon from '@material-ui/icons/Delete';
import ReceiptIcon from '@material-ui/icons/Receipt';
import DateFnsUtils from '@date-io/date-fns'; // choose your libs
import {API_URL} from '../../config.json';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import _ from 'lodash';
import ReactToPrint from "react-to-print";
import PrintIcon from '@material-ui/icons/Print';
import InstitutionProfile from '../commons/institution_profile'
import '../commons/commons.css'

import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';


import swal from 'sweetalert';
import commaNumber from 'comma-number';
let format = commaNumber.bindWith(',', '.')
const DebtorReceiptRecord = ({location,currentRoute,currentRouteSet,authInfo})=>{
    const classes = useStyles();
    useEffect(()=>{
        currentRouteSet(pathSpliter(location.pathname,1));
        getEmployees()
    },[]);
    let [items,itemsSet] = useState([]);

    let [customers,customersSet] = useState([]);
    let [locations,locationsSet] = useState([]);
    let [users,usersSet] = useState([]);
    let [employees,employeesSet] = useState([]);
    let [reportLoading,reportLoadingSet] = useState(false);
    
    let [selectedFilterType,selectedFilterTypeSet] = useState({filterType:'All'})
    let [selectedExpense,selectedExpenseSet] = useState(null)
    let [selectedEmployee,selectedEmployeeSet] = useState(null)
    let [selectedLocation,selectedLocationSet] = useState(null)
    let [selectedUser,selectedUserSet] = useState(null)
    let [selectedRecordType,selectedRecordTypeSet] = useState({recordType:'without details'})

    
    let [filterResult,filterResultSet] = useState([]);
    let [filterResultDetails,filterResultDetailsSet] = useState([]);
    let [details,detailsSet] = useState([]);
    
    

    let [print,printSet] = useState(false);
    let [loadingExpenses,loadingExpensesSet] = useState(false);
    let [customer_name,customer_name_set] = useState('');
    
    let [recordTypes,recordTypesSet] = useState([{recordType:'without details'},{recordType:'with details'}])
   
  



    useEffect(()=>{
          loadingExpensesSet(true)
          axios.post(`${API_URL}/api/get-accounts-by-search`,{query:customer_name.trim(),type:'indirect_expense'},{headers:{'auth-token':authInfo.token}}).then(res=>{
            customersSet(res.data)
            loadingExpensesSet(false)
          })
          
    },[customer_name])




    const getEmployees = async ()=>{
      await axios.post(`${API_URL}/api/get-employees`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
        employeesSet(res.data)
    })
    }

    const getLocations = async ()=>{
      await axios.post(`${API_URL}/api/get-locations`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
        locationsSet(res.data)
    })
    }


      const getUsers = async ()=>{
        await axios.post(`${API_URL}/api/get-users`,{'select-type': "active"},{headers:{'auth-token':authInfo.token}}).then(res=>{
          usersSet(res.data.message)
      })
      }

    let [byDateTime,byDateTimeSet] = useState({
          dateTimeFrom: currentDateStartTime(),
          dateTimeTo:currentDateEndTime()
    })


 
    useEffect(()=>{ 

        
       

        if(selectedFilterType != null && selectedFilterType.filterType=='By User' && users.length == 0 ){
            getUsers();
        }

        if(selectedFilterType != null && locations.length == 0 &&  (selectedFilterType.filterType=='By Location'  || selectedFilterType.filterType=='By Item')){
          getLocations();
        }

        filterResultSet([])
        filterResultDetailsSet([])
        detailsSet([])

        selectedExpenseSet(null)
        selectedLocationSet(null)
        selectedUserSet(null)
        selectedEmployeeSet(null)
       


    },[selectedFilterType,selectedRecordType]);



    let [filterTypes,filterTypesSet] = useState([{filterType:'All'},
    {filterType:'By Expense'},
    {filterType:'By Employee'},
    {filterType:'By User'}]);

    
    let getSearchResult = async ()=>{

      if(selectedFilterType == null){
        swal({
          title:'Select Filter Type',
          icon:'warning'
        })
        return false
      }
     
        let url =  `${API_URL}/api/get-expense-record`;

            if(selectedRecordType != null && selectedRecordType.recordType == 'with details' ){ 
                url = `${API_URL}/api/get-expense-with-details`
            }

            if(selectedFilterType != null && selectedFilterType.filterType == 'By Expense' ){
                url = `${API_URL}/api/get-expense-record-details`
            }
          


            
        let reqPayload = {
            selectedFilterType: selectedFilterType != null?selectedFilterType.filterType:null,
            expId: selectedExpense != null? selectedExpense.acc_id:null,
            employeeId: selectedEmployee != null? selectedEmployee.employee_id:null,
            userId: selectedUser != null ? selectedUser.user_id : null,
            fromDate: byDateTime.dateTimeFrom,
            toDate: byDateTime.dateTimeTo
        }

        
        reportLoadingSet(true)
        console.log(reqPayload)
        await axios.post(`${url}`,reqPayload,{headers:{'auth-token':authInfo.token}}).then(res=>{
             printSet(true)
             reportLoadingSet(false)

              
             if(selectedFilterType != null && selectedFilterType.filterType != 'By Expense' ){
               

                if(selectedRecordType != null && selectedRecordType.recordType == 'with details' ){ 
                    filterResultDetailsSet(res.data)
                }else{
                  filterResultSet(res.data);
                }


            }else{
                detailsSet(res.data)
            }

              
            
              
         

           
        })



    }


    useEffect(()=>{
      selectedEmployeeSet(null)
    },[selectedFilterType])




    let ReportDom = React.forwardRef( (props,ref) =>{
        return(
          <div ref={ref} >
                        <InstitutionProfile />

<div style={{clear:'both'}}></div>
<Paper style={{paddingLeft:'10px',paddingRight:'10px'}} className="print-source">
<p style={{width:'100%',textAlign:'center',fontWeight:'bold'}}>Expense   Record</p>
<div style={{float:'left',width:'50%',textAlign:'left',margin:0,padding:0,fontSize:'16px'}}>

<p style={{margin:'0px'}}>{selectedFilterType != null ?`Filter Type : ${selectedFilterType.filterType}`:''} </p>
<p style={{margin:'0px'}}>{selectedFilterType != null && (selectedFilterType.filterType == 'By Expense' ||  selectedFilterType.filterType == 'By Item')  ?`Expense Name : ${selectedExpense!=null?selectedExpense.acc_name:''}`:''}</p>
<p style={{margin:'0px'}}>{selectedFilterType != null && (selectedFilterType.filterType == 'By Expense' ||  selectedFilterType.filterType == 'By Item') ?` Institution  : ${selectedExpense!=null?selectedExpense.institution_name:''}`:''}</p>

  
<p style={{margin:'0px'}}>{selectedFilterType != null && (selectedFilterType.filterType == 'By Location' ||  selectedFilterType.filterType == 'By Item')?`Location : ${selectedLocation!=null?selectedLocation.location_name:''}`:''}</p>
<p style={{margin:'0px'}}>{selectedFilterType != null && selectedFilterType.filterType == 'By User'?`User : ${selectedUser!=null?selectedUser.user_name:''}`:''}</p>

</div>

<div style={{width:'10%'}}></div>

<div style={{float:'right',width:'40%',textAlign:'left',margin:0,padding:0,fontSize:'16px'}}>
<p style={{margin:'0px'}}>{selectedFilterType != null && (selectedFilterType.filterType == 'By Expense' ||  selectedFilterType.filterType == 'By Item') ?` Address  : ${selectedExpense!=null?selectedExpense.address:''}`:''}</p>
<p style={{margin:'0px'}}> {selectedFilterType != null &&( selectedFilterType.filterType == 'By Expense' ||  selectedFilterType.filterType == 'By Item')?` Contact No.  : ${selectedExpense!=null?selectedExpense.contact_no:''}`:''}</p>
<p style={{margin:'0px'}}>Statement  :  { moment(byDateTime.dateTimeFrom).format(dateFormat)  } - { moment(byDateTime.dateTimeTo).format(dateFormat) }</p>
</div>
 


</Paper> 

{
  (selectedRecordType !=null && selectedFilterType != null &&   filterResult.length>0 &&  selectedRecordType.recordType=='without details' && (selectedFilterType.filterType == 'All' || selectedFilterType.filterType == 'By Location' || selectedFilterType.filterType == 'By User' || selectedFilterType.filterType == 'By Employee') && (selectedFilterType.filterType!='By Expense'))?(
    <Paper className={classes.paper}  style={{paddingLeft:'10px',paddingRight:'10px',marginTop:'1px',marginBottom:'1px'}}>
    
    {/* sale Record  without details */} 
    <TableContainer >
      <Table className={classes.table} size="small" aria-label="a dense table" className="report-dom">
        <TableHead>
          <TableRow> 
            <TableCell align="left"  width="3%">SL</TableCell>
            <TableCell align="left"  width="5%">VCH No</TableCell>
            <TableCell align="left"  width="6%">Date</TableCell>
            <TableCell align="left"  width="15%">Payment From</TableCell>
            <TableCell align="left"  width="15%">Pay To</TableCell>
            <TableCell align="left"  width="5%">Payment By</TableCell>
            <TableCell align="left"  width="5%">Narration</TableCell>
            <TableCell align="right" width="8%">Total Amount</TableCell> 
          </TableRow>
        </TableHead>
        <TableBody>
            {
              filterResult.map((data,index)=>(
                <TableRow  key={index}> 
                <TableCell  align="left">{index+parseFloat(1)}</TableCell>
                <TableCell align="left">{data.exp_code}</TableCell> 
                <TableCell align="left">{moment(data.creation_date).format(dateFormat)}</TableCell>
                <TableCell align="left">{data.acc_name}</TableCell> 
                <TableCell align="left">{data.pay_to}</TableCell> 
                <TableCell align="left">{data.user_full_name}</TableCell>
                <TableCell align="left">{data.narration}</TableCell>
                <TableCell align="right">{format(parseFloat(data.exp_total).toFixed(2))}</TableCell>
               
              </TableRow>
              ))
            }
            
          

      
        <TableRow>
          <TableCell colSpan={7} style={{fontWeight:'bold',textAlign:'right'}}>Grand Total : </TableCell>
        
     
        
          <TableCell  style={{fontWeight:'bold',textAlign:'right'}}>{ format(filterResult.reduce((prev,curr)=>{
                return prev+parseFloat(curr.exp_total);
          },0).toFixed(2)) }</TableCell>
          
         
        </TableRow>
       


        </TableBody>
      </Table>
    </TableContainer>
        </Paper>
  ):''
}
    

{
  (selectedRecordType !=null && selectedFilterType != null &&   details.length>0 &&  (selectedFilterType.filterType=='By Expense'))?(
    <Paper className={classes.paper}  style={{paddingLeft:'10px',paddingRight:'10px',marginTop:'1px',marginBottom:'1px'}}>
    
    {/* sale Record  without details */} 
    <TableContainer >
      <Table className={classes.table} size="small" aria-label="a dense table" className="report-dom">
        <TableHead>
          <TableRow> 
            <TableCell align="left"  width="3%">SL</TableCell>
            <TableCell align="left"  width="5%">VCH No</TableCell>
            <TableCell align="left"  width="6%">Date</TableCell>
            <TableCell align="left"  width="15%">Payment For</TableCell>
            <TableCell align="left"  width="5%">Payment By</TableCell>
            <TableCell align="left"  width="5%">Narration</TableCell>
            <TableCell align="right" width="8%">Total Amount</TableCell> 
          </TableRow>
        </TableHead>
        <TableBody>
            {
              details.map((data,index)=>(
                <TableRow  key={index}> 
                <TableCell  align="left">{index+parseFloat(1)}</TableCell>
                <TableCell align="left">{data.exp_code}</TableCell> 
                <TableCell align="left">{moment(data.creation_date).format(dateFormat)}</TableCell>
                <TableCell align="left">{data.acc_name}</TableCell> 
                <TableCell align="left">{data.user_full_name}</TableCell>
                <TableCell align="left">{data.narration}</TableCell>

                <TableCell align="right">{format(parseFloat(data.exp_amount).toFixed(2))}</TableCell>
               
              </TableRow>
              ))
            }
            
          

      
        <TableRow>
          <TableCell colSpan={6} style={{fontWeight:'bold',textAlign:'right'}}>Grand Total : </TableCell>
        
     
        
          <TableCell  style={{fontWeight:'bold',textAlign:'right'}}>{ format(details.reduce((prev,curr)=>{
                return prev+parseFloat(curr.exp_amount);
          },0).toFixed(2)) }</TableCell>
          
         
        </TableRow>
       


        </TableBody>
      </Table>
    </TableContainer>
        </Paper>
  ):''
}

   {/* with details */}
   {
        (selectedRecordType != null && selectedFilterType != null   && filterResultDetails.length>0 && selectedRecordType.recordType=='with details' && (selectedFilterType.filterType == 'All'  || selectedFilterType.filterType == 'By Location' || selectedFilterType.filterType == 'By User'  || selectedFilterType.filterType == 'By Location' || selectedFilterType.filterType == 'By Employee' ) &&  (selectedFilterType.filterType!='By Expense'))?(
          <Paper className={classes.paper}  style={{paddingLeft:'10px',paddingRight:'10px',marginTop:'1px',marginBottom:'1px'}} >
    
    <TableContainer >
      <Table className={classes.table} size="small" aria-label="a dense table" className="report-dom">
        <TableHead>
          <TableRow >
            <TableCell align="left" width="3%">SL</TableCell>
            <TableCell align="left" width="5%">VCH</TableCell>
            <TableCell align="left" width="8%">Date</TableCell>
            <TableCell align="left" width="11%">Payment From</TableCell>
            <TableCell align="left" width="10%">Payment By</TableCell>
            <TableCell align="left" width="10%">Payment For</TableCell>
            <TableCell align="left" width="10%">Narration</TableCell>
        
            <TableCell align="right" width="10%">Payment Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            {
              filterResultDetails.map((data,index)=>(
                <>
                <TableRow  key={index}> 
                <TableCell  align="left">{index+parseFloat(1)}</TableCell>
                <TableCell align="left">{data.exp_code}</TableCell> 
                <TableCell align="left">{moment(data.creation_date).format(dateFormat)}</TableCell>
                <TableCell align="left">{data.acc_name}</TableCell>
                <TableCell align="left">{data.user_full_name}</TableCell>
                <TableCell align="left">{data.details[0].acc_name}</TableCell>
                <TableCell align="left">{data.narration}</TableCell>

              
               
                <TableCell align="right">{format(data.details[0].exp_amount)}</TableCell>
          

                
              </TableRow>

              
                   



                    {
                  data.details.slice(1).map((detail,dIndex)=>(
                    <TableRow key={dIndex}> 
                 
                      <TableCell  align="left"  colSpan={5}></TableCell>
                      <TableCell align="left">{detail.acc_name}</TableCell>
                 
                      <TableCell  align="left"  ></TableCell>

       
               
                <TableCell align="right">{format((detail.exp_amount).toFixed(2))}</TableCell>
                    </TableRow>
                  ))

                }


<TableRow> 
                   
                   <TableCell  style={{fontWeight:'bold',textAlign:'right'}} colSpan={7}> Sub Total </TableCell>


              
                   <TableCell  style={{fontWeight:'bold',textAlign:'right'}}> 
                        {format(parseFloat(_.sumBy(data.details,'exp_amount')).toFixed(2))}
                   </TableCell>

                 

                 
                   </TableRow>



                   </>
                
      ))
            }





                {/*  */}

            {
                <TableRow> 
               
              <TableCell  colSpan={7} style={{fontWeight:'bold',textAlign:'right'}}> Grand Total  : </TableCell>


            <TableCell   style={{fontWeight:'bold',textAlign:'right'}}>{format(filterResultDetails.reduce((prev,curr)=>{
                     return prev+parseFloat(curr.exp_total)
            },0).toFixed(2))}
          </TableCell>

           
              </TableRow>
            }
        </TableBody>
      </Table>
    </TableContainer>
        </Paper>
        ):''
      }




     



  
<div style={{clear: 'both',height:'10px'}}></div>
<div  className="print-source" style={{marginTop:'70px'}}>
                                <p style={{float:'left',borderTop:'1px solid #222',width:'150px',textAlign:'center'}}>Received By</p>
                           
                                 <p style={{float:'right',marginRight:'10px',borderTop:'1px solid #222',width:'150px',textAlign:'center'}}>Authorized By</p>
                      </div>
          </div>
        )
    })




    const reportRef = useRef();

   

      return(
          <div className={classes.root}> 
<Paper className={classes.paper} style={{marginTop:'-25px',marginBottom:'5px'}}>
<h4 style={{textAlign:'left',margin:0,padding:0,marginTop: '-10px',
    marginBottom: '4px'}}>Expense   Record</h4>
<Grid container spacing={3} > 


          <Grid item  xs={12}  sm={2}> 
          <Autocomplete 
          size="small"
          autoHighlight
              openOnFocus={true}
              style={{width:'100%',height:'20px'}}
              options={filterTypes} 
              value={selectedFilterType}
              getOptionLabel={(option) => option.filterType}
              onChange={(event,selectedObj)=>{
                selectedFilterTypeSet(selectedObj) 
              }}
              renderInput={(params) => <TextField 
               
                {...params} 
                label="Filter Type" 
                variant="outlined"
                />} 
          />

          </Grid>

         

         


          <Grid item  xs={12}   sm={2} style={{display: selectedFilterType!=null && (selectedFilterType.filterType=='By Expense' )?'block':'none'}}> 
          <Autocomplete 
           autoHighlight
           size="small"
             openOnFocus={true}
             style={{width:'100%',height:'20px'}}
             options={customers}
             loading={loadingExpenses}
             value={selectedExpense}
             getOptionLabel={(option) => option.acc_name}
             onChange={(event,selectedObj)=>{
                selectedExpenseSet(selectedObj) 
             }}
             renderInput={(params) => <TextField 
              label="Search Expense" 
               onChange={e => customer_name_set(e.target.value)} 
               {...params} 
               InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loadingExpenses ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
               variant="outlined"
               />} 
          
      />
          </Grid>


          <Grid item  xs={12}   sm={2} style={{display: selectedFilterType!=null && (selectedFilterType.filterType=='By Employee' )?'block':'none'}}> 
          <Autocomplete 
           autoHighlight
           size="small"
             openOnFocus={true}
             style={{width:'100%',height:'20px'}}
             options={employees}
             value={selectedEmployee}
             getOptionLabel={(option) => option.display_text}
             onChange={(event,selectedObj)=>{
                selectedEmployeeSet(selectedObj) 
             }}
             renderInput={(params) => <TextField 
              label="Select Employee" 
               {...params} 
               InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
               variant="outlined"
               />} 
          
      />
          </Grid>



          <Grid item  xs={12}   sm={2} style={{display: selectedFilterType!=null && (selectedFilterType.filterType=='By Location' || selectedFilterType.filterType=='By Item')?'block':'none'}}> 
          <Autocomplete 
          size="small"
          autoHighlight
              openOnFocus={true}
              style={{width:'100%',height:'20px'}} 
              options={locations}
              value={selectedLocation}
              getOptionLabel={(option) => option.location_name}
              onChange={(event,selectedObj)=>{
                 selectedLocationSet(selectedObj);
              }}
              renderInput={(params) => <TextField                
                {...params} 
                label="Select Location" 
                variant="outlined"
                />} 
                
          />
          </Grid>
        


     
         

          
       

         
        

          <Grid item  xs={12}   sm={2} style={{display: selectedFilterType!=null && selectedFilterType.filterType=='By User'?'block':'none'}}> 
          <Autocomplete  
          size="small"
          autoHighlight
              openOnFocus={true}
              style={{width:'100%',height:'20px'}}
              options={users}
              value={selectedUser} 
              getOptionLabel={(option) => option.user_name}
              onChange={(event,selectedObj)=>{
                 selectedUserSet(selectedObj)
              }}
              renderInput={(params) => <TextField 
                {...params} 
                label="Select User" 
                variant="outlined"
                />} 
                
          />
          </Grid>
{
  selectedFilterType != null && !(selectedFilterType.filterType=='By Expense'  )?(
    <Grid item  xs={12}   sm={2}  > 
    <Autocomplete  
    size="small"
    autoHighlight
        openOnFocus={true}
        style={{width:'100%',height:'20px'}} 
        options={recordTypes} 
        value={selectedRecordType} 
        getOptionLabel={(option) => option.recordType}
        onChange={(event,selectedObj)=>{
          selectedRecordTypeSet(selectedObj);
        }}
        renderInput={(params) => <TextField                
          {...params} 
          label="Record Type" 
          variant="outlined"
          />} 
          
    />
    </Grid>
  ):''
}
         

          
      
          <Grid item  xs={12}  sm={2} style={{marginBottom: ''}} > 
          
          



            <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={3}>
        <DesktopDatePicker
          label="From Date" 
          inputFormat={dateTimeFormat}
          value={byDateTime.dateTimeFrom}
          onChange={(datet)=>{
            byDateTimeSet({...byDateTime,dateTimeFrom:datet})
          }}
          renderInput={(params) => <TextField {...params} />}
        />
        
      </Stack>
    </LocalizationProvider>


          </Grid>


          <Grid item  xs={12}  sm={2} style={{marginBottom: ''}} > 


          

  <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={3}>
        <DesktopDatePicker
          label="To Date" 
          inputFormat={dateTimeFormat}
          value={byDateTime.dateTimeTo}
          onChange={(datet)=>{
            byDateTimeSet({...byDateTime,dateTimeTo:datet})
          }}
          renderInput={(params) => <TextField {...params} />}
        />
        
      </Stack>
    </LocalizationProvider>

          </Grid>
          <Grid item  xs={12}   sm={1} >
        <Button style={{marginTop: '5px',marginLeft: 'auto',fontSize:'10px'}} 
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            startIcon={<SearchIcon/>}
                            onClick={getSearchResult}
                            disabled={reportLoading?true:false}
                        >
                        Report 
                      </Button>
        </Grid>

        </Grid> 
        
        </Paper>
        {
          print?(
            <Grid container >
            <Grid item xs={12} sm={12} >
              <Paper style={{borderRadius:'0px',marginTop: '-7px'}}>
              <ReactToPrint
                          trigger={() => <PrintIcon  style={{cursor:'pointer',marginLeft: '30px',marginTop: '3px',marginBottom: '-8px'}} />}
                          content={() => reportRef.current}
                        />
              </Paper>
           
            </Grid>
        </Grid>
          ):''
        }
      
            <ReportDom ref={reportRef} /> 




          </div>
      )
}



const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    inputField:{
      width:'100%',
      marginTop:'5px'
    },
    plusLinkDiv:{
      position:'relative'  
    },
    plusLink:{
      margin: 0,
      padding: 0,
      marginTop: '-21px',
      fontSize: '29px',
      height: '21px',
      textAlign: 'right',
      position: 'absolute',
      right: 0,
      color: '#3e8d54'
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));


const mapStateToPops = (state)=>{
      return {
        currentRoute:state.currentRouteReducer,
        authInfo:state.authInfoReducer
      }
}
export default connect(mapStateToPops,{currentRouteSet})(DebtorReceiptRecord); 
